<h1 mat-dialog-title>Edit Tournament</h1>
<form [formGroup]="tournamentForm" novalidate (ngSubmit)="onSubmit()">
    <!-- Value: {{ tournamentForm.value | json }} -->


    <mat-form-field class="fill">
        <input matInput placeholder="Tournament Name" formControlName="name">
    </mat-form-field>
    <br>
    <!-- <mat-form-field>
        <mat-label>Start Date (local)</mat-label>
        <input matInput [matDatepicker]="dp3" readonly formControlName="date" (focus)="dp3.open()" (click)="dp3.open()">
        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
        <mat-datepicker #dp3 disabled="false"></mat-datepicker>
    </mat-form-field>
    <br>
    <mat-form-field>
        <mat-label>End Date (local)</mat-label>
        <input matInput [matDatepicker]="dp1" readonly formControlName="endDate" (focus)="dp1.open()" (click)="dp1.open()">
        <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
        <mat-datepicker #dp1 disabled="false"></mat-datepicker>
    </mat-form-field> -->
    <mat-form-field>
        <mat-label>Start Date (local)</mat-label>
        <!-- <input matInput [matDatepicker]="dp3" readonly formControlName="date" (focus)="dp3.open()" (click)="dp3.open()">
        <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
        <mat-datepicker #dp3 disabled="false"></mat-datepicker> -->

        <input matInput [ngxMatDatetimePicker]="picker" readonly formControlName="date" (focus)="picker.open()" (click)="picker.open()">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [showSeconds]="false" [defaultTime]="[12,0,0]">
        </ngx-mat-datetime-picker>
    </mat-form-field>
    <br>
    <mat-form-field>
        <mat-label>End Date (local)</mat-label>
        <!-- <input matInput [matDatepicker]="dp1" readonly formControlName="endDate" (focus)="dp1.open()" (click)="dp1.open()">
        <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
        <mat-datepicker #dp1 disabled="false"></mat-datepicker> -->
        <input matInput [ngxMatDatetimePicker]="picker2" readonly formControlName="endDate" (focus)="picker2.open()" (click)="picker2.open()">
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker2 [showSeconds]="false" [defaultTime]="[12,0,0]">
        </ngx-mat-datetime-picker>
    </mat-form-field>

    <br>
    <mat-form-field class="fill">
        <input matInput placeholder="Discord Link" formControlName="discord" pattern="^(?:https?:\/\/)?(?:www\.)?discord\.gg\/([a-zA-Z0-9]+)($|\?)">
        <mat-error *ngIf="discord.invalid && discord.touched">
            Please enter a valid link, Format: https://discord.gg/[code]
        </mat-error>
    </mat-form-field>

    <br>
    <mat-form-field *ngIf="users.length > 0">
        <mat-label>Owner</mat-label>
        <input type="text" matInput formControlName="owner" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.discordId">
                {{option.name}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>


    <br>
    <mat-form-field class="fill">
        <input matInput placeholder="Twitch Link" formControlName="twitchLink" pattern="^(?:https?:\/\/)?(?:www\.)?twitch\.tv\/([a-z0-9_]+)($|\?)">
        <mat-error *ngIf="twitch.invalid && (twitch.dirty || twitch.touched)">
            Please enter a valid twitch link, Format: https://twitch.tv/[channel name]
        </mat-error>
    </mat-form-field>

    <br>
    <mat-form-field class="fill">
        <input matInput placeholder="Prize Pool" formControlName="prize">
    </mat-form-field>

    <br>

    <label class="card-text">Tournament Info:</label> <br><br>
    <ckeditor formControlName="info" [config]="{ extraPlugins: ['colorbutton', 'iframe']}"></ckeditor>

    <br>
    <input type="file" (change)="onFileChanged($event)" #fileInput accept="image/png, image/jpeg, image/gif, image/webp, image/svg+xml, image/tiff">
    <br>
    <mat-checkbox formControlName="is_mini">
        Is mini tournament?
    </mat-checkbox>
    <br>

    <div mat-dialog-actions>
        <button mat-raised-button color="warn" mat-dialog-close>Close</button>
        <button mat-raised-button color="primary" type="submit" [disabled]="tournamentForm.invalid || isSubmitted">Update Tournament</button>
    </div>
</form>