<h1 mat-dialog-title>Edit {{data.selUser.name}}</h1>
<form [formGroup]="userForm" novalidate (ngSubmit)="onSubmit()">
    <!-- Value: {{ userForm.value | json }} -->
    <mat-form-field class="fill">
        <input matInput placeholder="Name" formControlName="name">
    </mat-form-field>
    <br>
    <mat-form-field class="fill">
        <input matInput placeholder="Score Saber ID" formControlName="ssId">
    </mat-form-field>
    <br>
    <mat-form-field class="fill">
        <input matInput placeholder="Twitch Name" formControlName="twitchName">
    </mat-form-field>
    <br>
    <label id="pronounlabel">Preferred Pronoun:</label>
    <mat-radio-group aria-labelledby="pronounlabel" formControlName="pronoun" class="pronounRadio">
        <mat-radio-button *ngFor="let pronoun of pronouns" class="radio-button" value="{{pronoun}}">{{pronoun}}
        </mat-radio-button>
        <mat-radio-button class="radio-button" value="Other">Other</mat-radio-button>
        <mat-form-field style="margin-top: 5px;" class="fill" *ngIf="pronouns.indexOf(userForm.value.pronoun) == -1">
            <input matInput placeholder="Preferred Pronoun" formControlName="pronoun">
        </mat-form-field>
    </mat-radio-group>
    <br>
    <label>Roles:</label>
    <div class="roles">
        <mat-checkbox *ngFor="let role of userRoles" (change)="updateRoleId(role.id)" [checked]="roleIds.includes(+role.id)" [disabled]="role.id <= minRole">
            {{role.name}}
        </mat-checkbox>
    </div>

    <div mat-dialog-actions>
        <button mat-raised-button color="warn" mat-dialog-close>Close</button>
        <button mat-raised-button color="primary" type="submit" [disabled]="userForm.invalid">Update user</button>
    </div>
</form>