<h1 mat-dialog-title>{{dialogTitle}}</h1>
<form [formGroup]="newPoolForm" novalidate (ngSubmit)="onSubmit()">
    <!-- Value: {{ newPoolForm.value | json }} -->


    <mat-form-field class="fill">
        <input matInput placeholder="Map Pool Name" formControlName="poolName">
    </mat-form-field>
    <br>
    <mat-form-field class="fill">
        <input matInput placeholder="Map Pool Description" formControlName="description">
    </mat-form-field>
    <br>
    <img src="{{newPoolForm.value.image}}" style="width: 48px;height: 48px;border: none;margin: 5px;">
    <br>
    <input type="file" (change)="onFileChanged($event)" #fileInput accept="image/png, image/jpeg, image/gif, image/webp, image/svg+xml, image/tiff">
    <br><br>
    <mat-checkbox formControlName="live">
        Is Live
    </mat-checkbox>
    <br>
    <mat-checkbox formControlName="is_qualifiers">
        Is Qualifiers pool?
    </mat-checkbox>

    <div mat-dialog-actions>
        <button mat-raised-button color="warn" mat-dialog-close>Close</button>
        <button mat-raised-button color="primary" type="submit" [disabled]="newPoolForm.invalid || isSubmitted">{{buttonMessage}}</button>
    </div>
</form>