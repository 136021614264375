<div *ngIf="!curUser else loaded">
    <img src="/assets/svg/BeatKhana Logo RGB animated.svg" style="display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100px;">
</div>

<ng-template #loaded>
    <div class="container">
        <div class="card noHover">
            <div class="card-body">
                <div class="right-container">
                    <img class="profileImg" src="{{ curUser.avatar }}" />
                    <ng-container *ngIf="user && (user['roleIds'].includes('1') || user['roleIds'].includes('2'))">
                        <div class="staff">
                            <button mat-stroked-button color="primary" (click)="editBadges()">Edit Badges</button>
                        </div>
                    </ng-container>
                </div>
                <h1>
                    {{ curUser.name }}
                    <img class="countryImg" src="\assets/flags/{{curUser.country | uppercase}}.png" alt="">
                </h1>
                <div *ngIf="curUser.badges.length > 0" class="badges">
                    <img *ngFor="let badge of curUser.badges" src="assets/badges/{{badge.image}}.png"
                        alt="{{badge.description}}" title="{{badge.description}}">
                </div>
                <p class="card-text">
                    <b>Global Rank:</b> #{{curUser.globalRank}} <br>
                    <b>Regional Rank:</b> #{{curUser.localRank}} <br>
                    <a *ngIf="user.ssId" href="https://scoresaber.com/u/{{curUser.ssId}}" target="_blank">Scoresaber</a>
                    <br>
                    <a href="https://twitch.tv/{{curUser.twitchName}}" target="_blank">Twitch</a>
                </p>
                <div style="float: left;">
                    <h4>Previous Tournaments</h4>
                    <ul class="card-text" style="margin-left:10px">
                        <li *ngFor="let tournament of curUser.tournaments">{{tournament}}</li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
</ng-template>