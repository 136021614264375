<section class="awards-title">
    <h1>Beat Saber Awards 2020</h1>
    <h4>Hosted by BeatKhana</h4>
</section>
<section class="awards-container">
    <div class="awards-nav">
        <ul>
            <li (click)="scroll(bestCoord)">Best Coordinator</li>
            <li (click)="scroll(bestStream)">Best Stream Host</li>
            <li (click)="scroll(bestEmote)">Best Emote</li>
            <li (click)="scroll(mapImpact)">Most Impactfull Map</li>
            <li (click)="scroll(mapKill)">Map That Could Kill</li>
            <li (click)="scroll(shitpostMap)">Best Shitpost Map</li>
            <li (click)="scroll(boomerMap)">Best Boomer Map</li>
            <li (click)="scroll(boomerMapper)">Best Boomer Mapper</li>
            <li (click)="scroll(mapper2019)">Best 2019 Mapper</li>
            <li (click)="scroll(mapper2020)">Best Zoomer Mapper</li>
            <li (click)="scroll(techMapper)">Best Tech Mapper</li>
            <li (click)="scroll(shitpostMapper)">Best Shitpost Mapper</li>
            <li (click)="scroll(streamer)">Best Streamer</li>
            <li (click)="scroll(mixedRealityStreamer)">Best Mixed Reality Streamer</li>
            <li (click)="scroll(bestYoutuber)">Best Youtuber</li>
            <li (click)="scroll(accAndy)">Best Acc Andy</li>
            <li (click)="scroll(bestBoomer)">Best Boomer</li>
            <li (click)="scroll(bsMeme)">Best Meme</li>
            <li (click)="scroll(leaderboardGod)">Leaderboard God</li>
            <li (click)="scroll(bestCaster)">Best Caster</li>
            <li (click)="scroll(bestLighter)">Best Lighter</li>
            <li (click)="scroll(bestTournament)">Best Tournament</li>
            <li (click)="scroll(bestMascot)">Best Mascot</li>
            <li (click)="scroll(bestSaberMaker)">Best Saber Maker</li>
            <li (click)="scroll(bestBlockMaker)">Best Block Maker</li>
            <li (click)="scroll(bestMod)">Best Mod</li>
            <li (click)="scroll(worstMenu)">Worst Menu</li>
            <li (click)="scroll(bestFurry)">Best Furry</li>
            <li (click)="scroll(bestVoice)">Best Voice</li>
            <li (click)="scroll(bestClan)">Best Clan</li>
            <li (click)="scroll(bestModder)">Best Modder</li>
        </ul>
    </div>
    <div class="awards-section">
        <div #bestCoord>
            <h2>Best Coordiantor for Tournaments</h2>
            <div class="nominees">
                <div class="nominee" routerLink='/user/464070190498447361'>
                    <div class="imgWrapper" style="background-image: url(https://new.scoresaber.com/api/static/avatars/76561198130607154.jpg);"></div>
                    <p>
                        Samiixd
                    </p>
                </div>
                <div class="nominee winner" routerLink='/user/158560971101569024'>
                    <div class="imgWrapper" style="background-image: url(https://new.scoresaber.com/api/static/avatars/76561198046397592.jpg);"></div>
                    <p>
                        Incanium
                    </p>
                </div>
                <div class="nominee" routerLink='/user/226507204495278080'>
                    <div class="imgWrapper" style="background-image: url(https://cdn.discordapp.com/avatars/226507204495278080/a_32da3c59988a15988b5880c63ba97c4b.webp?size=256);"></div>
                    <p>
                        Gregi
                    </p>
                </div>
                <div class="nominee" routerLink='/user/102133026196029440'>
                    <div class="imgWrapper" style="background-image: url(https://new.scoresaber.com/api/static/avatars/76561198271377161.jpg);"></div>
                    <p>
                        Beno (nee)
                    </p>
                </div>
                <div class="nominee" routerLink='/user/108207141315170304'>
                    <div class="imgWrapper" style="background-image: url(https://new.scoresaber.com/api/static/avatars/76561198039493984.jpg);"></div>
                    <p>
                        IcyNoobsGamer
                    </p>
                </div>
                <div class="nominee" routerLink='/user/450365431707074591'>
                    <div class="imgWrapper" style="background-image: url(https://new.scoresaber.com/api/static/avatars/76561198970517156.jpg);"></div>
                    <p>
                        Eradawn
                    </p>
                </div>
                <div class="nominee" routerLink='/user/277542487596466176'>
                    <div class="imgWrapper" style="background-image: url(https://new.scoresaber.com/api/static/avatars/76561198245321138.jpg);"></div>
                    <p>
                        ShadowPixel
                    </p>
                </div>
                <div class="nominee" routerLink='/user/580425653325791272'>
                    <div class="imgWrapper" style="background-image: url(https://new.scoresaber.com/api/static/avatars/76561198272266872.jpg);"></div>
                    <p>
                        Aso
                    </p>
                </div>
            </div>
        </div>
        <div #bestStream>
            <h2>Best Stream Host for Tournaments</h2>
            <div class="nominees">
                <div class="nominee" routerLink='/user/553576678186680340'>
                    <div class="imgWrapper" style="background-image: url(https://new.scoresaber.com/api/static/avatars/76561198040830896.jpg);"></div>
                    <p>
                        Ryeera
                    </p>
                </div>
                <div class="nominee" routerLink='/user/311273905094656001'>
                    <div class="imgWrapper" style="background-image: url(https://new.scoresaber.com/api/static/avatars/76561198056313463.jpg);"></div>
                    <p>
                        BakedaDough
                    </p>
                </div>
                <div class="nominee" routerLink='/user/226507204495278080'>
                    <div class="imgWrapper" style="background-image: url(https://cdn.discordapp.com/avatars/226507204495278080/a_32da3c59988a15988b5880c63ba97c4b.webp?size=256);"></div>
                    <p>
                        Gregi
                    </p>
                </div>
                <div class="nominee winner" routerLink='/user/145838716877930496'>
                    <div class="imgWrapper" style="background-image: url(https://new.scoresaber.com/api/static/avatars/76561197978021272.jpg);"></div>
                    <p>
                        Amanatsu
                    </p>
                </div>
            </div>
        </div>
        <div #bestEmote>
            <h2>Best Beat Saber Related Emote</h2>
            <div class="nominees">
                <div class="nominee emote">
                    <div class="imgWrapper" style="background-image: url(https://lh5.googleusercontent.com/A4VO4QDjXvFam7aQS3Wh7Kh0JGeR1-T1yX-_T4aTChP_jE42Tl3uuVr1j41oeTyYjkCeTOpocmaRdklZ-a9swLhHctEK9DtYE6q6ae1SylShMQsTfHzOVTj3smv0qQ=w260);"></div>
                    <p>
                        Wide Golfuh
                    </p>
                </div>
                <div class="nominee emote">
                    <div class="imgWrapper" style="background-image: url(https://lh6.googleusercontent.com/S1v4lSw9Vi-4ZY4mOrerleCtAKOhBufXeLcdB3ysfKVG5U0_yembxZp_YUy1Zsr46yxMfDl2r6rIPUvdOPWbhNxJ8FoXRgm3uvVe4IH_e-dScXS0oMY7Y4JIL8BUxQ=w128);"></div>
                    <p>
                        PepoSabers
                    </p>
                </div>
                <div class="nominee emote">
                    <div class="imgWrapper" style="background-image: url(https://lh4.googleusercontent.com/ugnuXWF7DeLsBSsM0WvIySL_D3bvSzS7R0M1VJtwIoZE355Vn-ljKBT0NI6CjbhyUwNHi03droL3qsd4hRozKD4Nuc63_MGy6VKWfbRs-ggtfdPy8sUoXbBKiA6wkQ=w80);"></div>
                    <p>
                        MonolithJaack
                    </p>
                </div>
                <div class="nominee emote winner">
                    <div class="imgWrapper" style="background-image: url(https://lh3.googleusercontent.com/Yj_49k3R3L4q-RwDZzU71YyEbmb8ZfvW6XyCDcgI3zN8dQSJOt9Oo8FCesxmO9kzMTWr1U3ky08654Rd6pu-Vf1jmZ_UBLqjt5tlW7pVaSysPTmWavofxElVMTrr7Q=w260);"></div>
                    <p>
                        SadTracking
                    </p>
                </div>
                <div class="nominee emote">
                    <div class="imgWrapper" style="background-image: url(https://lh5.googleusercontent.com/K1t4qs9w-F4nHFn_hd_cpno_BTiOSx9hrWzUOk_-eUeACDYKCgJdMnYQ6rgNvv8hEKisAtYL9aZazIVq-6z0g3DpZ2w_C5BGxWCfm2isRSS5DLdcgmW3nkRULhSkfQ=w45);"></div>
                    <p>
                        Ninja
                    </p>
                </div>
                <div class="nominee emote">
                    <div class="imgWrapper" style="background-image: url(https://lh5.googleusercontent.com/asY7o_WuhqumW_rbSFiiHlfRMDQonL7isVWtiL-5iqhhOFGHvMNbIYYJqPD8P67VbeixZOYlCd-Ydy_ziMEFJKWYrrwY5xPtkOfZTlIIsDD9HL9puhhYcyPIcVKl3A=w128);"></div>
                    <p>
                        Peepoacc
                    </p>
                </div>
                <div class="nominee emote">
                    <div class="imgWrapper" style="background-image: url(https://lh4.googleusercontent.com/sHLJtHETjtQBWE9oFsdTcY5HuuCEmE83jQ06A5DI8EOt09QDpdBLkZm12oI4XwRE7TueYyHM5j8uqF_o90YK4XqPWqR2_ASvcmWkxkDT2IcNXXgVlx3GRHMEmh9fZg=w56);"></div>
                    <p>
                        HahaBall
                    </p>
                </div>
                <div class="nominee emote">
                    <div class="imgWrapper" style="background-image: url(https://lh5.googleusercontent.com/KoK1zOBi4jiaxxqBpd37jhux8RiA1XL4NiKOB8TO09vnpu8lzZQm5f5ZRzpYcW6UjebipVy0HUSVFIht4haxNXAiv2hf9eHPKgjknHcKOL-eP9ZWweca-eH_4viXEg=w128);"></div>
                    <p>
                        WHAT HOW
                    </p>
                </div>
                <div class="nominee emote">
                    <div class="imgWrapper" style="background-image: url(https://lh5.googleusercontent.com/QMrCV-kEOz090BzF2lf_Z5FatCn6uPcpZquIhRo_FNz6wO2aiyW0msgT7ZXtqHdcmf0ejDTQCwYAa4nPc3Jc0vnhkjTrOEbr8Zamd_iiwfFpzcVkY_8K_zn7_kv8pQ=w96);"></div>
                    <p>
                        First Dreams
                    </p>
                </div>
                <div class="nominee emote">
                    <div class="imgWrapper" style="background-image: url(https://lh5.googleusercontent.com/nJ0mBuV9jSGFdMHlDmQEY67h0N6flTl_W3_oM-VrMDvYrHENV71knM1Vyydk-WVy14dhiri-fGu_PNrCClOAjmQQuQEyhqE7Ui58TlVGTMoNFgUAvDexov5p4i8Q_A=w112);"></div>
                    <p>
                        InterzW
                    </p>
                </div>
            </div>
        </div>
        <div #mapImpact>
            <h2>Map that has made the MOST impact on the game</h2>
            <div class="mapNominees">
                <a href="https://beatsaver.com/beatmap/1f90" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/1f90/2fddb136bda7f9e29b4cb6621d6d8e0f8a43b126.jpg);"></div>
                        <p>
                            Overkill - Nuketime
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/d0f6" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/d0f6/75ccd1def27021c38b58246f650802d9702bc70e.jpg);"></div>
                        <p>
                            Bassline Yatteru? - Uninstaller
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/76cb" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/76cb/898504255edd45a740490d831545e6d1330c1d72.jpg);"></div>
                        <p>
                            Goodbye - Puds
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/3036" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/songs/6C5B1FC2C75AA2ED3EBD69810A6456171D97703E.png);"></div>
                        <p>
                            Milk Crown - Hexagonial
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/25f" target="_blank">
                    <div class="nominee winner">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/25f/ad6c9f88d63259a95e39397c31be2981c4beb744.jpg);"></div>
                        <p>
                            Reality Check Through TYhe Skull - Rickput
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/3a" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/3a/9b0881baab8b72f33424ecf0d9ad247ea10afbb2.jpg);"></div>
                        <p>
                            TTFAF - Freek
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/e55" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/e55/8c51d94e3e247df86a4c7113e9fdbcc434a56f4b.jpg);"></div>
                        <p>
                            Cycle Hit - Various Mappers
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/b" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/b/19f2879d11a91b51a5c090d63471c3e8d9b7aee3.jpg);"></div>
                        <p>
                            Believer - Rustic
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/86a" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/86a/4692c6c200bc92364c268a518e9dee89146e4391.jpg);"></div>
                        <p>
                            Freedom Dive - Roffle
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/6136" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/songs/55F500A96EEFB234864A441DEB6754065E8ACA9F.png);"></div>
                        <p>
                            Ghos7 - DankruptMemer x Moriik
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/1a15" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/1a15/c1489448cce99d8dcf5b18c70223618284193408.jpg);"></div>
                        <p>
                            Nisemono - Hexagonial
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #mapKill>
            <h2>Ranked Map That Could Kill Someone</h2>
            <div class="mapNominees">
                <a href="https://beatsaver.com/beatmap/2200" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/2200/4867e299296a5b965edc4dcd930c07703b06b96e.jpg);"></div>
                        <p>
                            Daisuki - Hexagonial
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/78ca" target="_blank">
                    <div class="nominee winner">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/78ca/9f783ce7f810062852795f4cbdf8335245fd044a.jpg);"></div>
                        <p>
                            Tinnitus - Souk & Oddloop
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/76cb" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/songs/B506526ABA9AE3BC8C6DE045326D4F083607776B.png);"></div>
                        <p>
                            Psychology - Scrappy
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/4479" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/4479/e80adf683aed066f94bbba3e49162d7d0d7247b4.jpg);"></div>
                        <p>
                            Louder Than Steel - Electrostats
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/6b5f" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/6b5f/6a5aea3ce9a7a9f120ebd4deea377b4a54640bfb.jpg);"></div>
                        <p>
                            Break Expert Diff - Souk & Oddloop
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/2eea" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/2eea/9dc600ee749197f16ec4f75f5d5bf2ab38f8e4f8.jpg);"></div>
                        <p>
                            Labyrinth - Splake & Moriik
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/17b1" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/17b1/65f78118110793e75b81422d01e67b0c7623cff1.jpg);"></div>
                        <p>
                            sleepparalysis//////////////
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #shitpostMap>
            <h2>Best Shitpost Map</h2>
            <div class="mapNominees">
                <a href="https://beatsaver.com/beatmap/ff9" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/ff9/cb9f1581ff6c09130c991db8823c5953c660688f.jpg);"></div>
                        <p>
                            Smooth Criminal - Atlasik
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/6bf9" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/6bf9/77792ce1f1d03d9089a81c09f3affab6d488229f.jpg);"></div>
                        <p>
                            Armed and Ready - Jonothan Mullins
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/5851" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/5851/31138c054d7ca6b318c8d628015a5ccb996416e0.jpg);"></div>
                        <p>
                            Till I Collapse - Loloppe
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/86e" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/86e/280be3d96970f0222c75e9ddcffa8880418c8796.jpg);"></div>
                        <p>
                            Delfino Plaza - speederman5553
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/53d9" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/53d9/d8cd049712c82d272a2a6643e44cdcfdae040ce0.jpg);"></div>
                        <p>
                            The Office Main Theme - Anotymous
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/2c2" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/2c2/ddb478a5ea54861c7059c6dbf8c073ee37b31d65.jpg);"></div>
                        <p>
                            My Name is Jonas - diink
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee winner">
                        <div class="imgWrapper" style="background-image: url(https://gonewiththetwins.com/new/wp-content/uploads/2015/08/shrek.jpg);"></div>
                        <p>
                            The Shrek Movie - KingPeucheYT
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/7580" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/7580/627ae49b391a5a935eb29c4b9e321effc3900d9e.jpg);"></div>
                        <p>
                            Lolmao plays Louder Than Steel - FatBeanzoop
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/946c" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/songs/DC13CAE8586AA80AD4241BECDA91C24C842340CE.png);"></div>
                        <p>
                            Minecraft in Beat Saber - sboys3.com
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #boomerMap>
            <h2>Best Boomer Map</h2>
            <div class="mapNominees">
                <a href="https://beatsaver.com/beatmap/1db6" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/1db6/196d1061eac3cd4bc586e5afcaea07c35f1d69d0.jpg);"></div>
                        <p>
                            Setsuna Imitation - saut
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/250" target="_blank">
                    <div class="nominee winner">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/250/ad64505817b3e2ab6cc5c7007fcfa07f2c34d1f4.jpg);"></div>
                        <p>
                            Freedom Dive - Roffle
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/e27" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/e27/d3a0de31ebe3b61d3328d03a0e82c4152a9be5a8.jpg);"></div>
                        <p>
                            Blue Zenith - bananentropfen
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/2f94" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/2f94/200549108bb22752112c62d102bf69ee0c47fd7a.png);"></div>
                        <p>
                            DeltaMax - Yun0
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/27d8" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/27d8/d60ca1e3b6175d57dac7463d532989f83c257efb.jpg);"></div>
                        <p>
                            Monochrome Butterfly - fraies
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/17f9" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/17f9/108c239db3c0596f1ba7426353af1b4cc4fd8b08.jpg);"></div>
                        <p>
                            Tower of Heaven V2 - Zorowo
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/1a15/c1489448cce99d8dcf5b18c70223618284193408.jpg);"></div>
                        <p>
                            Nisemono - Hexagonial
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/7580" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/145c/87ecc58926a76c51a0719806e24f69e4c56c1d09.jpg);"></div>
                        <p>
                            Speed of Link (Hexagonial Diff)
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/songs/FF8C748A683122BAE2D2796B7290EC47EADC45CE.png);"></div>
                        <p>
                            Burnt Rice - Hexagonial
                        </p>
                    </div>
                </a>
                <a href="https://beatsaver.com/beatmap/14f8" target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/14f8/37650cf3a4da781b16fe47cff63df0df2e3e8a43.jpg);"></div>
                        <p>
                            Your Voice So - roeek
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #boomerMapper>
            <h2>Best Boomer Mapper (Best 2018 Mapper)</h2>
            <div class="mapNominees">
                <a arget="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Saut
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee winner">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198220149073.jpg);"></div>
                        <p>
                            Hexagonial
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/d4/d41f15a0beaee5e70131d68b92522b59c2e40964_full.jpg);"></div>
                        <p>
                            Freeek
                        </p>
                    </div>
                </a>
                <a arget="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Rustic
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198041191427.jpg);"></div>
                        <p>
                            Mystikmol
                        </p>
                    </div>
                </a>
                <a arget="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Krydar
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            GreatYazer
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Squeaksies
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            BlackBlazon
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198057326967.jpg);"></div>
                        <p>
                            Pug
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198056413446.jpg);"></div>
                        <p>
                            Iraky
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198011570317.jpg);"></div>
                        <p>
                            OrangeW
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Yun0
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561197965444209.jpg);"></div>
                        <p>
                            RedMagi
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #mapper2019>
            <h2>Best 2019 Mapper</h2>
            <div class="mapNominees">
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198051924392.jpg);"></div>
                        <p>
                            Light Ai
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198126979074.jpg);"></div>
                        <p>
                            puds
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg winner">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            DE125
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198063141432.jpg);"></div>
                        <p>
                            Hoppaw
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198163862964.jpg);"></div>
                        <p>
                            Fern
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198061865957.jpg);"></div>
                        <p>
                            Fraies
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198165926393.jpg);"></div>
                        <p>
                            Jez
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198082354639.jpg);"></div>
                        <p>
                            Nuketime
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198114011987.jpg);"></div>
                        <p>
                            Oddloop
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198043801246.jpg);"></div>
                        <p>
                            Joetastic
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198079502428.jpg);"></div>
                        <p>
                            Scrappy
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #mapper2020>
            <h2>Best Zoomer Mapper (Best 2020 Mapper)</h2>
            <div class="mapNominees">
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198150732193.jpg);"></div>
                        <p>
                            Rogdude
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198045559635.jpg);"></div>
                        <p>
                            FatBeanzoop
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee winner">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198333869741.jpg);"></div>
                        <p>
                            Cerret
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198082797048.jpg);"></div>
                        <p>
                            Nolanimations
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198032879786.jpg);"></div>
                        <p>
                            Teuflum
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198154857191.jpg);"></div>
                        <p>
                            Vilawes
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561197987180921.jpg);"></div>
                        <p>
                            CheckthePan
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198095106486.jpg);"></div>
                        <p>
                            muffin
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198281878350.jpg);"></div>
                        <p>
                            That_Narwhal
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198285246326.jpg);"></div>
                        <p>
                            ComplexFrequency
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #techMapper>
            <h2>Best Tech Mapper</h2>
            <div class="mapNominees">
                <a target="_blank">
                    <div class="nominee winner">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198180044686.jpg);"></div>
                        <p>
                            Cooling Closet
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198042919271.jpg);"></div>
                        <p>
                            Cheesydream
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Saut
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198065813548.jpg);"></div>
                        <p>
                            Uninstaller
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/af/afa2ff0e6e96e3603dfa715be825962225a39f15_full.jpg);"></div>
                        <p>
                            MaNaB
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            kizuflux
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198151324194.jpg);"></div>
                        <p>
                            Halcyon
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198362923485.jpg);"></div>
                        <p>
                            Tseska
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198122376182.jpg);"></div>
                        <p>
                            Schwank
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #shitpostMapper>
            <h2>Best Shitpost/Insane/Crack-Cocaine Mapper</h2>
            <div class="mapNominees">
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Sniffyz
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            BBBear
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198165735792.jpg);"></div>
                        <p>
                            bannanentropfen
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198073989976.jpg);"></div>
                        <p>
                            Loloppe
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198082394568.jpg);"></div>
                        <p>
                            CriminalCannoli
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198301082541.jpg);"></div>
                        <p>
                            miitchel
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee winner">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/oculus.png);"></div>
                        <p>
                            WDG_Mid
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #streamer>
            <h2>Best Streamer on Twitch</h2>
            <div class="mapNominees">
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198005985428.jpg);"></div>
                        <p>
                            Ejiejidayo
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198009689541.jpg);"></div>
                        <p>
                            Bandoot
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198075009026.jpg);"></div>
                        <p>
                            Tempex
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee winner">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198064659288.jpg);"></div>
                        <p>
                            Denyah
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198187936410.jpg);"></div>
                        <p>
                            Garsh
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/oculus.png);"></div>
                        <p>
                            TheJumpingSheep
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198045386379.jpg);"></div>
                        <p>
                            Duh Hello
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198362923485.jpg);"></div>
                        <p>
                            Tseska
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198089492236.jpg);"></div>
                        <p>
                            ezkills_
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #mixedRealityStreamer>
            <h2>Best Mixed Reality Streamer on Twitch</h2>
            <div class="mapNominees">
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198035698451.jpg);"></div>
                        <p>
                            Mawntee
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee winner">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198167393974.jpg);"></div>
                        <p>
                            LSToast
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198005025810.jpg);"></div>
                        <p>
                            Krayn
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198178407566.jpg);"></div>
                        <p>
                            Acetari
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198076378031.jpg);"></div>
                        <p>
                            ForeheadFox
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198033166451.jpg);"></div>
                        <p>
                            Silverhaze
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Lyfesaver74
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198101888037.jpg);"></div>
                        <p>
                            WulfgarinDnD
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198031152210.jpg);"></div>
                        <p>
                            Sehria_K
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #bestYoutuber>
            <h2>Best Beat Saber Youtuber</h2>
            <div class="mapNominees">
                <a target="_blank">
                    <div class="nominee winner">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198009689541.jpg);"></div>
                        <p>
                            Bandoot
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198075009026.jpg);"></div>
                        <p>
                            Tempex
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198005985428.jpg);"></div>
                        <p>
                            Ejejidayo
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198082797048.jpg);"></div>
                        <p>
                            Nolanimations
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Naluluna
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198153055189.jpg);"></div>
                        <p>
                            makeUmove
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://yt3.ggpht.com/ytc/AAUvwngv2ljR_MGhvaMM1nZaXbdz88IQFc9N4D170wcQpw=s88-c-k-c0x00ffffff-no-rj);"></div>
                        <p>
                            omotea
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://www.pcgamesn.com/wp-content/uploads/2019/04/beat-saber-jimmy-fallon.jpg);"></div>
                        <p>
                            Jimmy Fallon from the Tonight Show
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #accAndy>
            <h2>Best Acc Andy</h2>
            <div class="mapNominees">
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198166289091.jpg);"></div>
                        <p>
                            Rocker
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198186151129.jpg);"></div>
                        <p>
                            Pandita
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee winner">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/oculus.png);"></div>
                        <p>
                            SFK
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198167588802.jpg);"></div>
                        <p>
                            Brylanbbab
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198844720141.jpg);"></div>
                        <p>
                            Vinabby
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198999385463.jpg);"></div>
                        <p>
                            WoltixO
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198138161802.jpg);"></div>
                        <p>
                            Joshabi
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198110018904.jpg);"></div>
                        <p>
                            Whiisper
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #bestBoomer>
            <h2>Best Boomer</h2>
            <div class="mapNominees">
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561197978021272.jpg);"></div>
                        <p>
                            Amanatsu
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198077062414.jpg);"></div>
                        <p>
                            Woops
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198011570317.jpg);"></div>
                        <p>
                            OrangeW
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198217008717.jpg);"></div>
                        <p>
                            Magician
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198150732193.jpg);"></div>
                        <p>
                            Rogdude
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee winner">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198070511128.jpg);"></div>
                        <p>
                            AtomicX
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198156937943.jpg);"></div>
                        <p>
                            Yoshi
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198180044686.jpg);"></div>
                        <p>
                            CoolingCloset
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198040649885.jpg);"></div>
                        <p>
                            RandomKenny
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #bsMeme>
            <h2>Best Beat Saber Related Meme</h2>
            <div class="mapNominees">
                <a target="_blank">
                    <div class="nominee noImg winner">
                        <!-- <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/ff9/cb9f1581ff6c09130c991db8823c5953c660688f.jpg);"></div> -->
                        <p>
                            Beat Saber Songs taken literally
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <!-- <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/6bf9/77792ce1f1d03d9089a81c09f3affab6d488229f.jpg);"></div> -->
                        <p>
                            Tom Scott tracking loss
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <!-- <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/5851/31138c054d7ca6b318c8d628015a5ccb996416e0.jpg);"></div> -->
                        <p>
                            Multiplayer (85% done)
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <!-- <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/86e/280be3d96970f0222c75e9ddcffa8880418c8796.jpg);"></div> -->
                        <p>
                            Logan Rank 5
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <!-- <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/53d9/d8cd049712c82d272a2a6643e44cdcfdae040ce0.jpg);"></div> -->
                        <p>
                            BTS release video
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <!-- <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/2c2/ddb478a5ea54861c7059c6dbf8c073ee37b31d65.jpg);"></div> -->
                        <p>
                            World Cup Memes
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <!-- <div class="imgWrapper" style="background-image: url(https://gonewiththetwins.com/new/wp-content/uploads/2015/08/shrek.jpg);"></div> -->
                        <p>
                            ppV3 coming out anytime soon
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <!-- <div class="imgWrapper" style="background-image: url(https://beatsaver.com/cdn/7580/627ae49b391a5a935eb29c4b9e321effc3900d9e.jpg);"></div> -->
                        <p>
                            LinusTechTips
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <!-- <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/songs/DC13CAE8586AA80AD4241BECDA91C24C842340CE.png);"></div> -->
                        <p>
                            RCTTS, TTFAF, RAP GOD
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #leaderboardGod>
            <h2>Best LeaderBoard God</h2>
            <div class="mapNominees">
                <a target="_blank">
                    <div class="nominee winner">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/oculus.png);"></div>
                        <p>
                            Taichi
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198180044686.jpg);"></div>
                        <p>
                            CoolingCloset
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/oculus.png);"></div>
                        <p>
                            Astrella
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198333869741.jpg);"></div>
                        <p>
                            Cerret
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198011570317.jpg);"></div>
                        <p>
                            OrangeW
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198070511128.jpg);"></div>
                        <p>
                            AtomicX
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198154714707.jpg);"></div>
                        <p>
                            bckill
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #bestCaster>
            <h2>Best Caster for Tournaments</h2>
            <div class="mapNominees">
                <a target="_blank">
                    <div class="nominee winner">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198179181242.jpg);"></div>
                        <p>
                            Bantalope
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198175606462.jpg);"></div>
                        <p>
                            Wolven
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198217008717.jpg);"></div>
                        <p>
                            Magician
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198041191427.jpg);"></div>
                        <p>
                            Mystikmol
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198168188490.jpg);"></div>
                        <p>
                            Kosmos
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198043520886.jpg);"></div>
                        <p>
                            Zander
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198049906407.jpg);"></div>
                        <p>
                            Teapot
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561197992904475.jpg);"></div>
                        <p>
                            Ingorum
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #bestLighter>
            <h2>Best Lighter</h2>
            <div class="mapNominees">
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198036226075.jpg);"></div>
                        <p>
                            Aaltopah
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561197999988423.jpg);"></div>
                        <p>
                            ExUnreal
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198094952732.jpg);"></div>
                        <p>
                            TheRealZeroz
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198067735340.jpg);"></div>
                        <p>
                            Skeelie
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Aeroluna
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee winner">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198114011987.jpg);"></div>
                        <p>
                            Oddloop
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198976682243.jpg);"></div>
                        <p>
                            Pixelguy
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Loloppe (Lolighter)
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #bestTournament>
            <h2>Best Tournament</h2>
            <div class="tourneyNominees">
                <a target="_blank">
                    <div class="nominee winner">
                        <div class="imgWrapper" style="background-image: url(https://cdn.discordapp.com/icons/634814324405305392/7dfeb4a698ba86d708d38c67743c99d5.webp?size=128);"></div>
                        <p>
                            Beat Saber World Cup
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(/assets/images/2147484041.webp);"></div>
                        <p>
                            BeatKhana!'s Rumble Royale
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(/assets/images/2147484171.webp);"></div>
                        <p>
                            BeatKhana!'s Random Song Tournament
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(/assets/images/2147484184.webp);"></div>
                        <p>
                            The Beat Saber League
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(/assets/images/2147484071.webp);"></div>
                        <p>
                            Shitmiss City
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(/assets/images/2147484131.webp);"></div>
                        <p>
                            The Accuracy League
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(/assets/images/2147484182.webp);"></div>
                        <p>
                            Vision Block
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(/assets/images/2147484187.webp);"></div>
                        <p>
                            Hidden Sabers
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://media.discordapp.net/attachments/441807344591044619/582061239061184522/SSS_Orange_Wide.png?width=641&height=198);"></div>
                        <p>
                            Summer Saber Stars
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #bestMascot>
            <h2>Best Mascot</h2>
            <div class="mapNominees">
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://cdn.discordapp.com/emojis/606610063641280522.png?v=1);"></div>
                        <p>
                            Akane
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee winner">
                        <div class="imgWrapper" style="background-image: url(https://cdn.discordapp.com/emojis/606610948580573186.png?v=1);"></div>
                        <p>
                            Kanade
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://cdn.discordapp.com/emojis/627577894125699082.png?v=1);"></div>
                        <p>
                            Bill
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://cdn.discordapp.com/emojis/627577877982085120.png?v=1);"></div>
                        <p>
                            Paul
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://cdn.discordapp.com/emojis/638440635103182869.png?v=1);"></div>
                        <p>
                            CMB
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #bestSaberMaker>
            <h2>Best Saber Maker</h2>
            <div class="mapNominees">
                <a target="_blank">
                    <div class="nominee winner">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198257978632.jpg);"></div>
                        <p>
                            Mdot
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198084359108.jpg);"></div>
                        <p>
                            Altair
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198146373924.jpg);"></div>
                        <p>
                            Okachamie
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198335894744.jpg);"></div>
                        <p>
                            Parapass
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/oculus.png);"></div>
                        <p>
                            Raphpower
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198006074656.jpg);"></div>
                        <p>
                            Hooi
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Thot Dog
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #bestBlockMaker>
            <h2>Best Block Maker</h2>
            <div class="mapNominees">
                <a target="_blank">
                    <div class="nominee winner">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198076664624.jpg);"></div>
                        <p>
                            Bobbie
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/404.jpg);"></div>
                        <p>
                            Headassbtw
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/oculus.png);"></div>
                        <p>
                            Raphpower
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198335894744.jpg);"></div>
                        <p>
                            Parapass
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #bestMod>
            <h2>Best Mod</h2>
            <div class="mapNominees">
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Camera+
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            SaberTailor
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg winner">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            ScoreSaber
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            HitScoreVisualizer
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            SongBrowser
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Intro Skip
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Noodle Extensions
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Mapping Extensions
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Custom Avatars
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Particle Overdrive
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #worstMenu>
            <h2>Worst Beat Saber Menu</h2>
            <div class="mapNominees">
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Beta Menu (Pre 0.13.0)
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Full Release Menu (Post 0.13.0)
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg winner">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Post Multiplayer Menu (1.12.1)
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #bestFurry>
            <h2>Best Furry</h2>
            <div class="mapNominees">
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198019856958.jpg);"></div>
                        <p>
                            Cyansnow
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198063268251.jpg);"></div>
                        <p>
                            Moon
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198041649354.jpg);"></div>
                        <p>
                            Interz
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee winner">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198070511128.jpg);"></div>
                        <p>
                            AtomicX
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #bestVoice>
            <h2>Best Voice</h2>
            <div class="mapNominees">
                <a target="_blank">
                    <div class="nominee winner">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/oculus.png);"></div>
                        <p>
                            Astrella
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198374798540.jpg);"></div>
                        <p>
                            Ptopa
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198045386379.jpg);"></div>
                        <p>
                            Duh Hello (dikke bmw)
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198044562405.jpg);"></div>
                        <p>
                            Flee
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198175606462.jpg);"></div>
                        <p>
                            Wolven
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #bestClan>
            <h2>Best Clan</h2>
            <div class="mapNominees">
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            FOAA
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            WDG
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            ACC
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg winner">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Ball Sack Gaming
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            YEP
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            PWTFAATC Clan
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            FNAF Gaming
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            -VGN-
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            TN
                        </p>
                    </div>
                </a>
            </div>
        </div>

        <div #bestModder>
            <h2>Best Modder</h2>
            <div class="mapNominees">
                <a target="_blank">
                    <div class="nominee noImg winner">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Kyle1413
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198076664624.jpg);"></div>
                        <p>
                            Bobbie
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198088728803.jpg);"></div>
                        <p>
                            AurosVR
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198183820433.jpg);"></div>
                        <p>
                            Pink
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198036338094.jpg);"></div>
                        <p>
                            Shadnix
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Toni Macaroni
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Caeden117
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee noImg">
                        <div class="imgWrapper" style="background-image: url();"></div>
                        <p>
                            Megalon
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198063268251.jpg);"></div>
                        <p>
                            Moon
                        </p>
                    </div>
                </a>
                <a target="_blank">
                    <div class="nominee">
                        <div class="imgWrapper" style="background-image: url(https://scoresaber.com/imports/images/usr-avatars/76561198337126899.jpg);"></div>
                        <p>
                            Lillie
                        </p>
                    </div>
                </a>
            </div>
        </div>

    </div>
</section>