<div class="container gridArchive">
    <div *ngIf="loading else loaded">
        <img src="/assets/svg/BeatKhana Logo RGB animated.svg" style="display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100px;">
    </div>
    <ng-template #loaded>
        <div class="card archiveCard" *ngFor="let tournament of tournaments">
            <a [title]="tournament.name" [routerLink]="['/tournament',tournament.tournamentId]">
                <div>
                    <!-- <img src="https://beatkhanas3.s3.us-east-1.amazonaws.com/{{tournament.image}}" class="card-img"> -->
                    <img src="/assets/images/{{tournament.image}}" class="card-img">
                </div>
                <div class="card-body">
                    <h5 class="card-title">
                        {{tournament.name}}
                    </h5>
                    <p class="card-text">
                        1# {{tournament.first}} | #2 {{tournament.second}} | #3 {{tournament.third}}
                    </p>
                </div>
                <div class="card-body cardLeft">
                    <p class="card-text">{{tournament.startDate | date: 'dd/MM/yyyy'}}</p>
                </div>
            </a>
        </div>
    </ng-template>


    <ng-template #noArchive>
        <a>
            <div>
            </div>
            <div class="card-body">
                <h5 class="card-title">
                    No Archived Tournaments
                </h5>
            </div>
            <div class="card-body cardLeft">
            </div>
        </a>
    </ng-template>
</div>