<div class="card-body">
    <h1>{{tournament.name}}</h1>
    <h3>Staff</h3>
    <div *ngIf="loading else loaded">
        <img src="/assets/svg/BeatKhana Logo RGB animated.svg" style="display: block;
                margin-left: auto;
                margin-right: auto;
                width: 70px; 
                border: none;
                padding: 15px; ">
    </div>
    <ng-template #loaded>
        <div class="usrRow">
            <p class="card-text" *ngFor="let user of staff">
                <img class="teamImg" src="https://cdn.discordapp.com/avatars/{{user.discordId}}/{{user.avatar}}">
                <span class="teamName">{{user.name}}</span><br>
                <span style="font-size:smaller;">{{ getRoleNames(user.roles) }}</span><br>
                <a href="https://discord.com/users/{{ user.discordId }}"><i class="fab fa-discord"></i></a>
                <a href="https://www.twitch.tv/{{ user.twitchName }}"><i class="fab fa-twitch"></i></a>
            </p>
        </div>
        <ng-container *ngIf="this.userS.hasAdminPerms(tournament)">
            <hr>
            <button mat-stroked-button color="primary" (click)="openEdit()">Edit Staff</button> &nbsp;
        </ng-container>
    </ng-template>
</div>