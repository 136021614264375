<header *ngIf="showDefault">
    <nav>
        <div class="container">
            <div>
                <a routerLink="/">
                    <img src="/assets\svg\BeatKhana Logo Text RGB2.svg" alt="BeatKhana! Logo" height="50">
                </a>
            </div>
            <div class="nav-main">
                <ul class="navbar-nav">
                    <li><a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
                            class="nav-item">Home</a></li>
                    <li><a routerLink="/mini-tournaments" routerLinkActive="active"
                            [routerLinkActiveOptions]="{exact:true}" class="nav-item">Mini Tournaments</a></li>
                    <li><a routerLink="/archive" routerLinkActive="active" class="nav-item">Archive</a></li>
                    <li class="nav-item hover-dropdown">
                        More
                        <i></i>
                        <!-- <button class="nav-item dropdown-toggle">
                        </button> -->
                        <!-- More -->
                        <ul class="dropdown">
                            <li class="dropdown-item">
                                <a routerLink="/awards/2020" routerLinkActive="active">2020 Awards</a>
                            </li>
                            <li class="dropdown-item">
                                <a routerLink="/awards/2021" routerLinkActive="active">2021 Awards</a>
                            </li>
                            <li class="dropdown-item">
                                <a routerLink="/rules" routerLinkActive="active">Rules</a>
                            </li>
                            <li class="dropdown-item">
                                <a routerLink="/calendar" routerLinkActive="active">Calendar</a>
                            </li>
                            <li class="dropdown-item">
                                <a routerLink="/ta" routerLinkActive="active">TA Client</a>
                            </li>
                        </ul>
                    </li>
                    <!-- <li><a routerLink="/ranking" routerLinkActive="active" class="nav-item">Rankings</a></li> -->
                    <li><a routerLink="/team" routerLinkActive="active" class="nav-item">Team</a></li>
                </ul>
            </div>
            <div style="float: right;" class="nav-main">
                <ul class="navbar-nav">
                    <li>
                        <a href="https://discord.gg/5NjfSAC" target="_blank" class="nav-item noHover icon">
                            <i class="fab fa-discord"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.twitch.tv/beatkhana" target="_blank" class="nav-item noHover icon">
                            <i class="fab fa-twitch"></i>
                        </a>
                    </li>
                    <li *ngIf="user" class="nav-item has-dropdown noHover">
                        <button class="nav-link dropdown-toggle">
                            <img class="userImg" src="{{ user.avatar }}" style="display: inline;" height="64"
                                width="64">

                        </button>
                        <ul class="dropdown">
                            <li class="dropdown-item">
                                <a routerLink="/profile">View Profile</a>
                            </li>
                            <li class="dropdown-item">
                                <a href="/api/logout">Logout</a>
                            </li>
                        </ul>
                    </li>
                    <li *ngIf="!user" style="margin-left: 5px;">
                        <a href="/api/login?url={{this.router.url}}" class="nav-item">Login/Register</a>
                    </li>
                </ul>
            </div>


            <!-- Mobile nav-->
            <div class="nav-mobile">

            </div>
            <div class="nav-mobile">
                <div class="hamburger hamburger--spin js-hamburger" (click)="burgerClick()"
                    [ngClass]="burgerActive ? 'is-active' : ''">
                    <div class=" hamburger-box ">
                        <div class="hamburger-inner "></div>
                    </div>
                </div>
            </div>

            <div [ngClass]="burgerActive ? 'mobile-show' : ''" class="mobile-nav-content" style="grid-column: span 3;">
                <div>
                    <ul class="navbar-nav mobile">
                        <li><a routerLink="/" routerLinkActive="active " [routerLinkActiveOptions]="{exact:true} "
                                class="nav-item ">Home</a></li>
                        <li><a routerLink="/mini-tournaments" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact:true}" class="nav-item">Mini Tournaments</a></li>
                        <li><a routerLink="/archive" routerLinkActive="active " class="nav-item ">Archive</a></li>
                        <li><a routerLink="/awards/2020" routerLinkActive="active" class="nav-item ">2020 Awards</a>
                        </li>
                        <li><a routerLink="/awards/2021" routerLinkActive="active" class="nav-item ">2021 Awards</a>
                        </li>
                        <li><a routerLink="/rules" routerLinkActive="active" class="nav-item">Rules</a></li>
                        <!-- <li><a routerLink="/ranking" routerLinkActive="active " class="nav-item ">Rankings</a></li> -->
                        <li><a routerLink="/calendar" routerLinkActive="active" class="nav-item">Calendar</a></li>
                        <li><a routerLink="/team" routerLinkActive="active " class="nav-item ">Team</a></li>
                    </ul>
                </div>
                <div>
                    <hr>
                    <ul class="navbar-nav mobile">
                        <li *ngIf="user">
                            <img class="userImg" src="{{ user.avatar }} " style="display: inline-box;" height="64"
                                width="64">
                        </li>
                        <li *ngIf="user">
                            <a routerLink="/profile" class="nav-item ">View Profile</a>
                        </li>
                        <li *ngIf="user">
                            <a href="/api/logout" class="nav-item ">Logout</a>
                        </li>
                        <li *ngIf="!user ">
                            <a href="/api/login?url={{this.router.url}}" class="nav-item ">Login/Register</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nav>
</header>
<router-outlet></router-outlet>
<footer *ngIf="showDefault">
    <p>
        <strong>BeatKhana!</strong>
        <br><br>
        <a title="Join the Discord!" href="https://discord.gg/5NjfSAC" target="_blank"><i
                class="fab fa-discord"></i></a>
        <a title="Join the Discord!" href="https://discord.gg/5NjfSAC" target="_blank" [innerHTML]="discordSvg"
            class="icon2"><i class="icon2 discord"></i></a>
        <a title="Follow us on twitch!" href="https://www.twitch.tv/beatkhana" target="_blank"><i
                class="fab fa-twitch"></i></a>
        <a title="Get Saber-O's Merch" href="http://teespring.com/en/stores/saber-os" target="_blank"><i
                class="fas fa-tshirt"></i></a>
    </p>
</footer>
