<h1>Edit sign up</h1>
<form [formGroup]="signUpForm" novalidate (ngSubmit)="onSubmit()">
    <!-- Value: {{ signUpForm.value | json }} -->
    <br>
    <mat-form-field>
        <mat-label>{{signUpComment}}</mat-label>
        <textarea matInput formControlName="comment"></textarea>
        <mat-error *ngIf="comment.invalid && comment.touched">
            This tournament requires you to provide: {{signUpComment}} in order to sign up
        </mat-error>
    </mat-form-field>

    <div mat-dialog-actions>
        <button mat-raised-button color="warn" mat-dialog-close>Close</button>
        <button mat-raised-button color="primary" type="submit" [disabled]="signUpForm.invalid">Update sign Up</button>
    </div>
</form>