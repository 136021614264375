<div class="container">
    <div class="card noHover" style="border-radius: 0px 4px 4px 4px;">
        <div class="card-body">
            <h1>Logs</h1>

            <div class="example-loading-shade" *ngIf="isLoadingResults">
                <!-- <mat-spinner *ngIf="isLoadingResults"></mat-spinner> -->
                <img src="/assets/svg/BeatKhana Logo RGB animated.svg" style="display: block;
        margin-left: auto;
        margin-right: auto;
        width: 100px; border: none;">
            </div>
            <table mat-table [dataSource]="logs" class="card-text" matSort>
                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef>User</th>
                    <td mat-cell *matCellDef="let log">
                        {{log.name}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="log">
                    <th mat-header-cell *matHeaderCellDef>Log</th>
                    <td mat-cell *matCellDef="let log">
                        {{log.log}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="time">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Time</th>
                    <td mat-cell *matCellDef="let log">
                        {{log.time | date: 'dd/MM/yyyy HH:mm:ss'}}
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
            </table>
            <mat-paginator [length]='resultsLength' [pageSize]="25"></mat-paginator>
        </div>
    </div>
</div>