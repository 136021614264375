<h1>Generate Bracket</h1>
<form [formGroup]="bracketGenForm" novalidate (ngSubmit)="onSubmit()">
    <!-- Value: {{ bracketGenForm.value | json }} -->
    <p class="card-text">
        By generating a bracket you will completely wipe the existing bracket (if there is one).
    </p>
    <br>
    <mat-checkbox formControlName="custom" [disabled]="bracketGenForm.value.manual">
        Custom Users
    </mat-checkbox>
    <br>
    <mat-checkbox formControlName="manual" [disabled]="bracketGenForm.value.custom">
        Manually Select Users
    </mat-checkbox>
    <br><br>

    <p class="card-text" *ngIf="bracketGenForm.value.custom">
        By selecting custom users you overwrite any signed up users and users will not have their progress recorded.
        <br> Other tournament bracket settings will also apply here such as the player limit and random sort
        <br> If this is your intention then continue by adding each seperate player name on a new line below.
    </p>
    <mat-form-field *ngIf="bracketGenForm.value.custom">
        <mat-label>Enter each user on a new line</mat-label>
        <textarea matInput rows="8" formControlName="players"></textarea>
    </mat-form-field>

    <ng-container *ngIf="bracketGenForm.value.manual">
        <mat-form-field *ngFor="let item of [].constructor(bracketGenForm.value.users?.length + 1); let i = index;">
            <mat-label>Seed {{i+1}}</mat-label>
            <input type="text" (focus)="updateLastEvent($event.target.value)" (input)="updateFilter($event.target.value)" matInput [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="updatePlayers($event)" [displayWith]="displayFn.bind(this)">
                <mat-option *ngFor="let option of filteredOptions" [value]="option.discordId">
                    {{option.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </ng-container>

    <div mat-dialog-actions>
        <button mat-raised-button color="warn" mat-dialog-close>Close</button>
        <button mat-raised-button color="primary" type="submit" [disabled]="bracketGenForm.invalid">Generate</button>
    </div>
</form>