<!-- <p>rules works!</p> -->
<div class="container">
    <div class="card noHover">
        <div class="card-body">
            <h1>Standardized rules</h1>
            <p class="card-text">
                Here you can find a set of standardized rules compiled by BeatKhana! and other major tournament hosts.
            </p>
            <ul class="accordion">
                <li>
                    <input type="checkbox" checked>
                    <i></i>
                    <h2 class="infoHeader">Whitelisted mods</h2>
                    <p class="card-text">
                        Here is a list of approved mods that will be used by most tournaments. If you have a mod that is not on this list it is likely that you will not be allowed to use it in a tournament setting. <br><br> BeatSaverDownloader <br> BeatSaverVoting
                        <br> BeatSaviourData <br> BS_Utils <br> BSML <br> CameraPlus <br> Custom Campaigns <br> CustomJSONData <br> CustomSaber <br> Custom Notes (Notes must be approved with screenshots) <br> Custom Walls <br> FencingQualifierPlugin <br>                        FPFCToggle <br> HitScoreVisualizer <br> ImageCoverExpander <br> MusicEscape <br> NoodleExtensions <br> NoteLineRemover <br> No360HUD <br> NoteSpawnEffectRemover <br> ParticleOverdrive <br> PlaylistLoaderLite <br> PracticePlugin
                        <br> RumbleMod <br> ScoreSaber <br> SongDataCore <br> Song-refresh-button <br> TournamentAssistant <br> YeetDebris <br> BSIPA <br> Saber Factory <br> CustomMenuPointers <br> Custom Campaigns
                        <br> RandomSongPlayer <br> Chroma <br> JustRainbowLights <br> PerfectionDisplay <br> MappingExtensions <br> StreamCore <br> TransparentWalls <br> YUR Fit Calorie Tracker <br> HTTPStatus <br> CustomMenuText <br> CustomFailText <br>                        CustomMissText <br> CustomMenuMusic <br> SnapTurn <br> SongInfoViewer <br> NoFailCheck <br> BeatFollower <br> Enhancements <br> Music Spatializer <br> SmoothCameraUnlimited <br> FireworksDisabler <br> AdaptiveSFXRemover <br> Enhanced
                        Search And Filters <br> SongBrowser <br> SongPlayHistory <br> Counters+ <br> ScorePercentage <br> SongChartVisualizer <br> Custom Exit <br> Level Pack Panel Mover <br> ChatCore
                    </p>
                </li>
                <li>
                    <input type="checkbox" checked>
                    <i></i>
                    <h2 class="infoHeader">Replays</h2>
                    <p class="card-text">
                        A replay is simply when the map is replayed, this can be called for any reason. <br> Each party will get one replay call per match, however only one replay is allowed per map. <br> Both parties will be told their scores before
                        being required to call a replay.
                    </p>
                </li>
            </ul>
        </div>
    </div>
</div>