<div class="container">
    <div class="card noHover">
        <div class="card-body">
            <h1 style="text-align: center;"><i class='fas fa-angle-left' style="float: left;" (click)="changeDate(-1)"></i> {{curMonth}} {{curYear}} <i class='fas fa-angle-right' style="float: right;" (click)="changeDate(1)"></i></h1>
            <div class="calendar">
                <div class="headers">
                    <span>Monday</span>
                    <span>Tuesday</span>
                    <span>Wednesday</span>
                    <span>Thursday</span>
                    <span>Friday</span>
                    <span>Saturday</span>
                    <span>Sunday</span>
                </div>
                <div class="dates">
                    <div *ngFor="let date of dates" class="date" [ngClass]="{'today': date.today}" style="grid-column: {{date.col + 1}}; grid-row: {{date.row + 1}};">
                        <span *ngIf="date.date != 0" class="dateNum">{{date.date}}</span>
                    </div>
                    <!-- <div style="grid-area: 4 / 7 / auto / span 2; z-index: 10;
                    background: rgba(255, 255, 255, 0.465); height: 10px;">
                        event :)
                    </div> -->
                    <!-- <div *ngFor="let n of numSequence(6); let i = index;" class="row" style="grid-row: {{i + 1}}">

                    </div> -->

                    <div *ngFor="let event of displayEvents" class="event" [routerLink]="['/tournament',event.id]" style="grid-area: {{event.row + 1}} / {{event.start}} / auto / {{event.end}}; z-index: 10;
                    background: {{event.color}}80; margin-top: {{event.top}}px">
                        <p>
                            {{event.name}}
                        </p>
                        <!-- {{event | json}} -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>