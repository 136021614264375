<div class="card-body">
  <h1>{{tournament.name}}</h1>
  <h3>Qualifiers</h3>

  <div *ngIf="loading else loaded">
    <img src="/assets/svg/BeatKhana Logo RGB animated.svg" style="display: block;
            margin-left: auto;
            margin-right: auto;
            width: 70px;
            border: none;">
  </div>

  <ng-template #loaded>
    <button *ngIf="isAuth" style="float: right;" (click)="taSync()" mat-stroked-button color="primary">Sync With
      TA</button>
    <br>
    <mat-tab-group animationDuration="300ms" dynamicHeight>
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="labelText">All Songs</span>
        </ng-template>
        <mat-accordion>
          <mat-expansion-panel hideToggle *ngFor="let user of qualsScores; let i = index" class="playerPanel"
            [disabled]="user.cutoff || user.scores.length == 0" [ngClass]="{'cutoff' : user.cutoff}">
            <mat-expansion-panel-header>

              <mat-panel-title class="playerName" *ngIf="!user.cutoff">
                #{{user.position}} <img class="participantImgSmall" src="{{user.avatar}}"> <a
                  routerLink="/user/{{user.discordId}}">{{user.name}}</a>
              </mat-panel-title>
              <mat-panel-description class="playerSumary" *ngIf="!user.cutoff">
                <b style="color: #fff">{{user.scoreSum}}</b> &nbsp; ({{user.avgPercentage}}%)
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p *ngIf="!user.cutoff" class="card-text">
              <span *ngFor="let score of user.scores">
                <b style="color: #fff">#{{score.position+1}}</b> &nbsp;
                <a href="https://beatsaver.com/beatmap/{{score.key}}" target="_blank">
                  {{score.songName}} - {{score.songAuthor}}
                </a>
                <span class="mapperName">[{{score.levelAuthor}}]</span>
                <span style="float: right; color: rgba(255, 255, 255, 0.7);">
                  <b class="rawScore" style="color: #fff">{{score.score}}</b> &nbsp;
                  ({{(score.percentage *
                                    100).toFixed(2)}}%)
                </span>
                <br>
              </span>
            </p>
          </mat-expansion-panel>

        </mat-accordion>
      </mat-tab>
      <mat-tab *ngFor="let leaderboard of leaderboards | keyvalue">
        <ng-template mat-tab-label>
          <img src="https://scoresaber.com/imports/images/songs/{{leaderboard.key}}.png" class="labelImage">
          <span class="labelText">{{findSong(leaderboard.key).name}}</span>
        </ng-template>
        <mat-accordion>
          <mat-expansion-panel hideToggle *ngFor="let user of leaderboard.value; let i = index" class="playerPanel"
            [disabled]="true">
            <mat-expansion-panel-header>
              <mat-panel-title class="playerName" *ngIf="!user.cutoff">
                #{{i+1}} <img class="participantImgSmall" src="{{user.avatar}}"> <a
                  routerLink="/user/{{user.discordId}}">{{user.name}}</a>
              </mat-panel-title>
              <mat-panel-description class="playerSumary" *ngIf="!user.cutoff">
                <b style="color: #fff">{{user.score}}</b> &nbsp; ({{user.percentage}}%)
              </mat-panel-description>
            </mat-expansion-panel-header>
          </mat-expansion-panel>

        </mat-accordion>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</div>
